import request from '@/utils/axios'

/** 获取合作伙伴列表 */
export function getList(params) {
  return request({
    url: '/union/partner',
    method: 'get',
    params
  })
}

/** 获取合作伙伴详情 */
export function getDetail(id) {
  return request({
    url: `/union/partner/detail?id=${id}`,
    method: 'get'
  })
}

/** 添加合作伙伴 */
export function add(data) {
  return request({
    url: '/union/partner',
    method: 'post',
    data
  })
}

/** 编辑合作伙伴 */
export function edit(data) {
  return request({
    url: '/union/partner',
    method: 'put',
    data
  })
}

/** 删除合作伙伴 */
export function del(id) {
  return request({
    url: `/union/partner?id=${id}`,
    method: 'delete'
  })
}

// 启用合作伙伴
export function enable(params) {
  return request({
    url: '/union/partner/enable',
    method: 'get',
    params
  })
}

// 禁用合作伙伴
export function disable(params) {
  return request({
    url: '/union/partner/disable',
    method: 'get',
    params
  })
}

// 推荐合作伙伴
export function recommend(data) {
  return request({
    url: '/union/logo/recommend',
    method: 'post',
    data
  })
}

<template>
  <div>
    <div class="filter-container">
      <el-form inline>
        <el-form-item>
          <el-input v-model="query.name" placeholder="请输入名称" clearable />
        </el-form-item>
        <el-form-item>
          <el-select v-model="query.status" placeholder="请选择状态" clearable>
            <el-option v-for="(value, key) in status" :key="key" :value="key" :label="value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
          <el-button type="danger" icon="el-icon-refresh" @click="refresh">重置</el-button>
          <el-button type="primary" icon="el-icon-plus" @click="add">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list">
      <el-table-column
        prop="id"
        label="ID"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="id"
        label="logo"
        align="center"
        width="320px"
      >
        <template slot-scope="{ row }">
          <el-image :src="row.imgUrl" style="width: 300px;height: 120px" />
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="名称"
        align="center"
        show-overflow-tooltip
      />
      <!-- <el-table-column
        prop="posterType"
        label="类型"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          {{ types[row.posterType] }}
        </template>
      </el-table-column> -->
      <el-table-column
        prop="status"
        label="状态"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          {{ status[row.status] }}
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        label="操作"
        align="center"
      >
        <template slot-scope="{ row }">
          <el-row>
            <el-button size="small" type="primary" @click="edit(row.id)">编辑</el-button>
            <el-button size="small" type="danger" @click="del(row.id)">删除</el-button>
          </el-row>
          <el-row style="margin-top:10px;">
            <el-button v-if="row.status" size="small" type="danger" @click="disable(row.id)">禁用</el-button>
            <el-button v-else size="small" type="success" @click="enable(row.id)">启用</el-button>
            <el-button v-if="row.recommend" type="danger" size="small" @click="recommend(row.id, 0)">取消推荐</el-button>
            <el-button v-else type="primary" size="small" @click="recommend(row.id, 1)">推荐</el-button>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-container">
      <el-pagination
        background
        :total="count"
        :page-sizes="pageSizes"
        :page-size.sync="pageSize"
        :current-page.sync="page"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="refresh"
        @current-change="(val) => {
          page = val
          getList()
        }"
      />
    </div>
    <Form ref="form" />
  </div>
</template>

<script>
import list from '@/mixins/list'
import * as api from '@/api/partner'
import Form from './form'
export default {
  components: { Form },
  mixins: [list],
  data() {
    return {
      api,
      status: ['禁用', '启用']
    }
  },
  methods: {
    enable(id) {
      this.api.enable({ id }).then(() => {
        this.$message({
          message: '启用成功',
          type: 'success'
        })
        this.getList()
      })
    },
    disable(id) {
      this.api.disable({ id }).then(() => {
        this.$message({
          message: '禁用成功',
          type: 'success'
        })
        this.getList()
      })
    },
    recommend(id, recommend) {
      this.api.recommend({ id: id, recommend: recommend }).then(() => {
        this.$message({
          message: '操作成功',
          type: 'success'
        })
        this.getList()
      })
    }
  }
}
</script>

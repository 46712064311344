<template>
  <el-dialog
    :title="`${id ? '编辑' : '新增'}合作伙伴`"
    :visible.sync="show"
    width="600px"
  >
    <el-form v-if="show" :model="form" label-width="auto">
      <el-form-item label="名字">
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item label="跳转类型">
        <el-select v-model="form.posterType" placeholder="请选择类型" clearable style="width: 100%">
          <el-option v-for="(value, key) in posterType" :key="key + 1" :value="key + 1" :label="value" />
        </el-select>
      </el-form-item>
      <el-form-item label="logo">
        <el-upload
          action="#"
          :http-request="uploadImage"
          :show-file-list="false"
          :limit="1"
        >
          <el-image v-if="form.imgUrl" :src="form.imgUrl" />
          <i v-else class="el-icon-plus uploader-icon" />
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="链接">
        <el-input v-model="form.coverUrl" placeholder="请输入小程序APPID或公众号链接" />
      </el-form-item>
    </el-form>
    <el-row type="flex" justify="end">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import form from '@/mixins/form'
import * as api from '@/api/partner'

export default {
  mixins: [form],
  data() {
    return {
      api,
      show: false,
      form: {
        imgUrl: ''
      },
      posterType: ['活动类海报', '小程序链接', '公众号链接', '小程序内部链接']
    }
  },
  methods: {
    uploadImage({ file }) {
      this.upload(file).then(res => {
        this.form.imgUrl = res.data.fileUrl
      })
    }
  }
}
</script>
